.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.form-input-container{
  display: flex;
  /* background-color: aliceblue; */
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  width: 100%;
  margin: 5px 0px 5px;
}

.input-side{
  display: flex;
  /* gap:5px; */
  width: 90%;
}
.custom-form{
  display: flex;
  /* border: 1px solid black; */
  align-items: center;
  /* margin: 10px; */
}
.inner-form1{
  /* background-color: red; */
  width: 50%;
  display: flex;
  justify-content: center;
}
.inner-form2{
  /* background-color: green; */
  width: 50%;
}
.inner-form1-2{
  /* background-color: red; */
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner-form2-2-blue{
  /* background-color: green; */
  width: 60%;
  display: flex;
  background-color: #024f86;
  border-radius: 4px;
  align-items: center;
}
.inner-form2-2-grey{
  /* background-color: green; */
  width: 60%;
  display: flex;
  background-color: rgb(70, 68, 68);
  border-radius: 4px;
  align-items: center;
}
.inside-form2-1{
  width: 50%;
}
.inside-form2-2{
  width: 50%;
  color: white;
  text-align: center;
}
.input-text{
  width: 100%;
  border: 1px solid black;
  border-radius: 4px;
}
.input-text2{
  width: 100%;
  border: 1px solid black;
  border-radius: 4px 0px 0px 4px;
}

.button-side{
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-button{
  /* margin: 10px; */
  display: flex;
  width: 80%;
  /* justify-content: space-between; */
  gap: 2px;
  align-items: center;
  justify-content: center;
  background-color: rgb(46, 164, 46);
  border: 1px solid green;
  border-radius: 5px;
  color: white;

}

@media (max-width: 1250px){
.input-side{
  font-size: 11px;
}
.button-side{
  font-size: 12px;
}
.search-button{
  font-size: 10px;
}
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}