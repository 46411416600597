body {
  /* margin: 40px; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  font-family: Arial;
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
}
.teks-break {
  word-break: break-all;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.required-star {
  color: red;
}
.form-group .col-form-label {
  font-size: 20px !important;
}
td,
th {
  font-size: 14px;
}

.btn {
  color: white;
}
.btn-warning:hover {
  color: white;
}
.time-size {
  width: 70px;
}
.author-size {
  width: 120px;
}
tbody {
  display: block;
  /* height: calc(100%-469px); */
  overflow-y: scroll;
}
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 65px;
  height: 65px;
  border: 8px solid #f3f3f3;
  border-top: 8px solid#024f86;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: #cad4de;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

#basic-addon2 {
  background-color: #024f86;
  color: #ffffff;
  width: 120px;
  border-radius: 0px 7px 7px 0px;
  height: 43px;
}

#basic-addon3 {
  background-color: #444444;
  color: #ffffff;
  width: 120px;
  border-radius: 0px 7px 7px 0px;
  height: 43px;
}
.line-box {
  background-color: #024f86;
  height: 5px;
}
.form-control {
  border-radius: 7px;
  height: 43px;
}
.btn-search {
  background-color: #3B893B;
  height: 41px;
  width: 145px;
  color: #ffffff;
  border-radius: 7px;
  font-size: 20px;
  text-align: center;

}
.btn-login {
  background-color: #024f86;
  height: 41px;
  width: 145px;
  color: #ffffff;
  border-radius: 7px;
  font-size: 20px;
  margin-top: 20px;
}
.btn-change-password {
  background-color: #024f86;
  height: 41px;
  width: 250px;
  color: #ffffff;
  border-radius: 7px;
  font-size: 20px;
  margin-top: 20px;
}
.btn-search:hover {
  color: #ffffff;
}
.btn-matched {
  background-color: #72d542;
  height: 41px;
  width: 170px;
  color: #ffffff;
  border-radius: 7px;
  border-color: #72d542;
  font-size: 20px;
  margin: 15px;
}
.btn-matched:hover {
  color: #ffffff;
  background-color: #72d542;
  border-color: #72d542;
}
.btn-show {
  background-color: #47a1fb;
  height: 36px;
  width: 97px;
  color: #ffffff;
  border-radius: 7px;
  font-size: 18px;
}
.btn-show:hover {
  color: #ffffff;
}
.btn-seek {
  background-color: #ffb941;
  height: 36px;
  width: 40%;
  color: #ffffff;
  border: none;
  border-radius: 7px;
  font-size: 18px;
}
.btn-seek:hover {
  color: #ffffff;
}
input[type="text"] {
  font-size: 20px;
}
.table .thead-light th {
  background-color: #024f86 !important;
  border-color: #024f86 !important;
  color: #ffffff;
  font-size: 17px;
  height: 56px;
}
.count-badge {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%; 
  height: 38px;
  line-height: 38px;
  text-align: center;
  background-color: hsla(354, 86%, 78%, 0.3);
  border-radius: 8px;
}

.text-seek{
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: 38px;
  line-height: 38px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}
.seek-badge {
  padding-left: 5px;
  padding-right: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: 35px;
  line-height: 38px;
  background-color: #f9cd80;
  border-radius: 5px;
}
.seek-message {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #f9cd80;
  border-radius: 10px;
}
.no-data {
  font-size: 20px;
  height: 560px;
}
.card-size {
  width: 800px;
}
.navbar-title {
  font-size: 20px;
  color: white !important;
}
.navbar-title:hover {
  color: red !important;
}
.title {
  color: white !important;
  font-weight: bold;
  font-size: 25px;
}
.primary-color {
  color: "#024f86" !important;
}
.pointer {
  cursor: pointer;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.alert-success {
  animation: fadeIn 0.5s ease-in-out;
}
.card-header {
  font-size: 30px;
  font-weight: bold;
}
.user-text {
  font-size: 25px;
}
.container-margin {
  margin: 0px 30px 30px 30px;
}
.form-control::placeholder {
  font-size: 20px !important;
}

.login-container {
  width: 600px;
}
.header-modal {
  background-color: #024f86 !important;
  color: #ffffff !important;
}

.custom-container { 
  /* margin-right: 15px;  */
}

.video-container{
  min-height: 350px;
  max-height: 350px;
}

/* scrollable chart */
.chart-container{
  background-color: aliceblue; 
  max-height: 350px; 
  max-width: 700px;
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
}

.chartWrapper{
  position: absolute;
  top: -20px;
  background-color:#f3f3f3;
  height: 100%;
  width: 48vw;
} 

.chart{
  max-height: 350px;
}
/* end scrollable chart */

.table tbody tr .left-table{
    height: 50px;
    padding: 5px;
  }

.action-wrapper{
  display: flex;
  justify-content: space-between;
}
.btn-view {
    text-align: center;
    background-color: #5193f7;
    height: 36px;
    width: 40%;
    color: #ffffff;
    border: none;
    border-radius: 7px;
    font-size: 18px;

 }

 .timestamp-field{
    width: 40%;
 }
 .count-field{
    width: 30%;
 }
 .action-field{
    width: 30%;
 }
/* @media (max-width: 1250px) { */
@media (max-width: 1550px) {

/* scrollable chart */
.chart-container{
  background-color: aliceblue; 
  max-height: 350px;
  /* max-width: 700px; */
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
}

.chartWrapper{
  position: absolute;
  top: -20px;
  background-color:#f3f3f3;
  height: 100%;
  width: 48vw;
} 

.chart{
  max-height: 200px;
}
/* end scrollable chart */
.video-container{
  min-height: 200px;
  max-height: 200px;
}

  .nav-register {
    width: 80px;
  }
  .nav-change {
    width: 165px;
  }
  .nav-logout {
    width: 100px;
  }
  .navbar-title {
    font-size: 18px;
    color: white !important;
  }
  .form-group .col-form-label {
    font-size: 17px !important;
  }
  .btn-search {
    height: 35px;
    width: 110px;

    font-size: 15px;
  }
  .form-control {
    height: 35px;
  }
  #basic-addon2 {
    width: 60px;
    height: 35px;
  }
  #basic-addon3 {
    width: 60px;
    height: 35px;
  }
  .btn-matched {
    height: 35px;
    width: 150px;
    color: #ffffff;
    font-size: 17px;
    margin: 15px;
  }
  input[type="text"] {
    font-size: 16px;
  }
  .table .thead-light th {
    background-color: #024f86 !important;
    border-color: #024f86 !important;
    color: #ffffff;
    font-size: 12px;
    height: 25px !important;
  }
  .btn-show {
    height: 30px;
    width: 70px;
    font-size: 12px;
  }
  .btn-seek {
    height: 30px;
    /* width: 40px; */
    width: 40%;
    font-size: 10px;
    text-align: center;
  }
  .btn-view {
    height: 30px;
    /* width: 40px; */
    width: 40%;
    font-size: 10px;
    text-align: center;
  }
  td,
  th {
    font-size: 12px;
  }
  .count-badge {
    width: 50px;
    height: 30px;
    line-height: 30px;
    border-radius: 10px;
  }

  .form-control::placeholder {
    font-size: 17px !important;
  }
  
  /* .table tbody tr .left-table{
    height: 50px;
  } */
  .table tbody tr .left-table{
    height: 30px;
    padding: 5px;
  }
  .text-seek{
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: 38px;
  line-height: 38px;
  padding-left: 5px;
  padding-right: 5px;
}
.seek-badge {
  padding-left: 5px;
  padding-right: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: 100%;
  line-height: 38px;
  background-color: #f9cd80;
  border-radius: 5px;
}
tbody {
  display: block;
  /* height: 60vh; */
  overflow-y: scroll;
}
}
